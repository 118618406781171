import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import DefaultLayout from '@/layouts/default';
import SEO from '@/components/layout/seo';

const NotFoundPage = ({ location }) => (
  <DefaultLayout pageInfo={location}>
    <SEO title="Page Not found" />
    <Container className="py-5">
      <Row>
        <Col lg={6}>
          <div className="py-5">
            <h1>Page Not Found</h1>
            <p>Sorry, the page you are looking for no longer exists.</p>
            <p>
              <Link to="/" className="standalone">
                Go Back Home
              </Link>
            </p>
          </div>
        </Col>
        <Col lg={6} />
      </Row>
    </Container>
  </DefaultLayout>
);
NotFoundPage.propTypes = {
  location: PropTypes.shape(),
};

NotFoundPage.defaultProps = {
  location: null,
};

export default NotFoundPage;
